import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import SwedishBankIdJwtSnippet from '../../../snippets/jwt-payloads/swedish-bankid';
import NorwegianBankIdJwtSnippet from '../../../snippets/jwt-payloads/norwegian-bankid';
import NorwegianBankIdBiometricJwtSnippet from '../../../snippets/jwt-payloads/norwegian-bankid-biometric';
import NorwegianVippsJwtSnippet from '../../../snippets/jwt-payloads/norwegian-vipps';
import DanishMitIDJwtSnippet from '../../../snippets/jwt-payloads/danish-mitid';
import DanishMitIDAddressJwtSnippet from '../../../snippets/jwt-payloads/danish-mitid-address';
import DanishMitIDCompanySignatoryJwtSnippet from '../../../snippets/jwt-payloads/danish-mitid-erhverv-company-signatory';
import DanishMitIDErhvervJwtSnippet from '../../../snippets/jwt-payloads/danish-mitid-erhverv';
import FtnJwtSnippet from '../../../snippets/jwt-payloads/finnish-trust-network';
import BelgianEidJwtSnippet from '../../../snippets/jwt-payloads/belgian-eid';
import BelgianItsmeJwtSnippet from '../../../snippets/jwt-payloads/belgian-itsme';
export const _frontmatter = {
  "product": "verify",
  "category": "Getting Started",
  "sort": 4,
  "title": "JWT payloads pr. eID type",
  "subtitle": "Examples of JWT payloads for selected eIDs"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Below you will find the structure and example content for the JSON Web Tokens, the ID tokens, returned when signing in with each of the supported eID types.`}</p>
    <p>{`The examples below illustrate only the fields that are user-specific. The actual JWTs issued contain other more technical, OIDC conformant, fields as well, which are mostly relevant for validation and session maintenance purposes.`}</p>
    <h2>{`Sweden`}</h2>
    <h3>{`BankID`}</h3>
    <SwedishBankIdJwtSnippet mdxType="SwedishBankIdJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`ssn`}</inlineCode>{` field is the social security number.`}</p>
    <h2>{`Norway`}</h2>
    <h3>{`BankID`}</h3>
    <NorwegianBankIdJwtSnippet mdxType="NorwegianBankIdJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`socialno`}</inlineCode>{` field is the social security number. The `}<inlineCode parentName="p">{`uniqueUserId`}</inlineCode>{` identifies the legal person corresponding to the login, and is not considered sensitive.`}</p>
    <h3>{`BankID Biometric`}</h3>
    <NorwegianBankIdBiometricJwtSnippet mdxType="NorwegianBankIdBiometricJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`socialno`}</inlineCode>{` field is the social security number.`}</p>
    <h3>{`Vipps`}</h3>
    <NorwegianVippsJwtSnippet mdxType="NorwegianVippsJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`socialno`}</inlineCode>{` field is the social security number.`}</p>
    <h2>{`Denmark`}</h2>
    <h3>{`MitID for citizens`}</h3>
    <DanishMitIDJwtSnippet mdxType="DanishMitIDJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`cprNumberIdentifier`}</inlineCode>{` field is the social security number.`}</p>
    <h3>{`MitID for citizens (with address lookup)`}</h3>
    <DanishMitIDAddressJwtSnippet mdxType="DanishMitIDAddressJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`cprNumberIdentifier`}</inlineCode>{` field is the social security number.`}</p>
    <h3>{`MitID for company signatories`}</h3>
    <DanishMitIDCompanySignatoryJwtSnippet mdxType="DanishMitIDCompanySignatoryJwtSnippet" />
    <h3>{`MitID Erhverv (MitID for business)`}</h3>
    <DanishMitIDErhvervJwtSnippet mdxType="DanishMitIDErhvervJwtSnippet" />
    <h2>{`Finland`}</h2>
    <h3>{`Finnish Trust Network - BankID`}</h3>
    <FtnJwtSnippet mdxType="FtnJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`hetu`}</inlineCode>{` field is the personal identity code.`}</p>
    <h3>{`Finnish Trust Network - Mobillivarmenne`}</h3>
    <p>{`Same as BankID, except the `}<inlineCode parentName="p">{`satu`}</inlineCode>{` property will have a value as well.`}</p>
    <h2>{`Belgium`}</h2>
    <h3>{`Itsme - Basic level, with Extra ID Data and Security Data enabled`}</h3>
    <BelgianItsmeJwtSnippet mdxType="BelgianItsmeJwtSnippet" />
    <a name="beeid"></a>
    <h3>{`eID - verified, with national number, personal info, photo and address enabled`}</h3>
 <BelgianEidJwtSnippet mdxType="BelgianEidJwtSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      